import React from 'react';

import { THAILAND_COLLECTION_URL } from '@jsv3/enums/urlEnums';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const CollectionLink = () => (
  <a
    href={THAILAND_COLLECTION_URL}
    id="collection-link"
    className="main-header__collection-logotype"
  >
    <img src="/images/collection/white-lotus.png" alt="Check out Trips“" />
    <span>Check out Four Seasons Thailand White Lotus Trips</span>
  </a>
);

export default ErrorBoundaryDecorator()(CollectionLink);
